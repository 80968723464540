export const SITE_NAME = 'RentalCover';
export const OTP_CODE_LENGTH = 6;
export const EMAIL_DOMAIN_SUFFIX_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const AUTH_TOKEN_STORAGE_NAME = 'B2CJWT';
export const EN_US_LOCALE_TEXT = 'en-us';
export const CURRENT_LOCALE = 'CURRENT_LOCALE';
export const EN_LOCALE_TEXT = 'en';
export const MOMENT_CODES = {
  hk: 'zh-hk',
  zh: 'zh-cn',
  tw: 'zh-tw',
};
