import React from 'react';
import { StyledSecondaryNavBar, StyledContainer } from '@whitelabel/rentalcover-shared/styled/StyledSecondaryNavBar';
import { ProtectionButton } from '../PrimaryNavBar/NavButtons';

const SecondaryNavBar = () => (
  <StyledSecondaryNavBar>
    <StyledContainer>
      <ProtectionButton />
    </StyledContainer>
  </StyledSecondaryNavBar>
);

export default SecondaryNavBar;
