import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.claimsPortal.home.title',
    defaultMessage: 'Claims',
  },
  claimDetailsSummary: {
    id: 'pages.claimsPortal.details.summary',
    defaultMessage: 'Summary',
  },
  claimDetailsPayments: {
    id: 'pages.claimsPortal.details.payments',
    defaultMessage: 'Payments',
  },
  claimDetailsDocuments: {
    id: 'pages.claimsPortal.details.supportingDocuments',
    defaultMessage: 'Supporting documents',
  },
  documentsError: {
    id: 'claimDocuments.error',
    defaultMessage:
      'There is a problem with one or more of your supporting documents. So we can continue assessing your claim, please upload new files',
  },
  documentsSuccess: {
    id: 'claimDocuments.success',
    defaultMessage: 'Your documents have been submitted and are ready for assessment.',
  },
  documentsClosedInfo: {
    id: 'claimDocuments.closedInfo',
    defaultMessage:
      'Your claim has been closed due to lack of evidence. To allow us to continue assessing your claim, please upload new files.',
  },
  claimProgressIssue: {
    id: 'claimDocuments.claimProgressIssue',
    defaultMessage: 'There is a problem with the file you previously provided and we cannot progress your claim',
  },
  newFileUploadRequest: {
    id: 'claimDocuments.newFileUploadRequest',
    defaultMessage: 'Please upload a new file for this document type.',
  },
  claimDetailsCommunications: {
    id: 'pages.claimsPortal.details.communications',
    defaultMessage: 'Communications',
  },
  claimDetails: {
    id: 'fnol.FNOLClaimDetailsInitialForm.claimDetails',
    defaultMessage: 'Claim Details',
  },
  insuredLabelCyberProtection: {
    id: 'insuredLabel.cyberProtection',
    defaultMessage: 'Cyber Protection',
  },
  payExcess: {
    id: 'claimPayments.payExcess',
    defaultMessage: 'Pay excess',
  },
  getMyPayment: {
    id: 'claimPayments.getMyPayment',
    defaultMessage: 'Get my payment',
  },
  payExcessBanner: {
    id: 'claimPayments.payExcessBanner',
    defaultMessage:
      'In order to progress your claim, we now require you to pay your excess as outlined in your {policyWording}. You will be asked to enter your payment details on the following page.',
  },
  getMyPaymentBannerSuccess: {
    id: 'claimPayments.getMyPaymentBannerSuccess',
    defaultMessage:
      'Your claim has been approved! Click "Get my payment" and enter your bank details on the following page to receive your payment.',
  },
  getMyPaymentBannerFail: {
    id: 'claimPayments.getMyPaymentBannerFail',
    defaultMessage:
      'Your payment has failed. Please click "Get my payment" and re-enter your payment details to try again.',
  },
  status: {
    id: 'claimPayments.status',
    defaultMessage: 'Status',
  },
  helpCenter: {
    id: 'help.helpCenter',
    defaultMessage: 'Help centre',
  },
  paymentDateLabel: {
    id: 'bookingCard.paymentDateLabel',
    defaultMessage: 'Payment date',
  },
  referenceNo: {
    id: 'bookingDetailsCard.referenceNo',
    defaultMessage: 'Reference No.',
  },
  uploadYourDocument: {
    id: 'documentUpload.uploadYourDocument',
    defaultMessage: 'Upload your document',
  },
  defaultBrowserExitWarning: {
    id: 'defaultBrowserExitWarning',
    defaultMessage: 'Changes that you made may not be saved.',
  },
  claimAssessmentDelayBanner: {
    id: 'claimAssessmentDelayBanner',
    defaultMessage:
      "Due to an increase in claim volumes, claim assessments are taking slightly longer than expected. As soon as your claim progresses, your claim status will be updated to 'Under Assessment'. We apologise for the inconvenience.",
  },
});
