import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

// 'intl' service singleton reference
let intl: IntlShape;

export function IntlGlobalProvider({ children }: { children: React.ReactNode }) {
  intl = useIntl(); // Keep the 'intl' service reference
  return <>{children}</>;
}

/**
 * Singleton service to access the 'intl' service
 */
export function appIntl() {
  return intl ?? {};
}
