import { setCookie } from '@whitelabel/helpers/utils';
import { ICustomer } from './types';
import { AUTH_TOKEN_STORAGE_NAME } from './constants';

export const getFullnameFromUser = (user?: ICustomer) => user && `${user.firstName} ${user.lastName}`;

export const setAuthCookie = (token: string, hasExpireTime = true) => {
  // Token expires in 1 month
  const expiresInSeconds = 1 * 30 * 24 * 60 * 60;
  setCookie(
    AUTH_TOKEN_STORAGE_NAME,
    token,
    hasExpireTime ? new Date(new Date().getTime() + expiresInSeconds * 1000) : null,
  );
};
