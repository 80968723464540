import React, { useEffect } from 'react';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { usePageViews } from '@whitelabel/helpers/hooks';
import { authRoutes } from '../../routes/routes';
import ErrorPage from '../../components/ErrorPage';
import ProtectedLayout from '../layout/ProtectedLayout';
import { store } from '../../store';

document.body.className += ' rentalcover-theme';

const App = () => {
  const { pathname } = useLocation();
  const { path: basePath } = useRouteMatch();

  useEffect(() => {
    setupListeners(store.dispatch);
  }, []);

  usePageViews();

  return (
    <Switch>
      <Redirect
        from="/(.+)/"
        strict
        exact
        to={{ pathname: pathname.slice(0, -1), search: window.location.search, hash: window.location.hash }}
      />
      {authRoutes.map(({ component: Component, path, ...rest }) => (
        <Route {...rest} path={`${basePath}${path}`}>
          <ProtectedLayout>
            <Component />
          </ProtectedLayout>
        </Route>
      ))}
      <Route render={(routerProps) => <ErrorPage {...routerProps} isNoMatch />} />
    </Switch>
  );
};

export default App;
