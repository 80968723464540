import languagesMain from '@whitelabel/helpers/languages.json';
import { ILocales } from '@whitelabel/helpers/types';
import { MOMENT_CODES } from './constants';
import languagesRC from './constants/languagesRC.json';

const getLocaleData = (xcode: string) => {
  // Get locale on xcode format from languagesMain (helpers package). flagMap also uses xcode
  const { nameLocal, bidi } = languagesMain[xcode as keyof typeof languagesMain] ?? {
    nameLocal: 'English',
    bidi: false,
  };

  return {
    nameLocal,
    bidi,
  };
};

type ILocalesBase = Omit<ILocales, 'Flag'> & { xcode: string };

export const locales = Object.keys(languagesRC).reduce<Record<string, ILocalesBase>>((availableLocales, locale) => {
  const { urlPrefix, name, xcode } = languagesRC[locale as keyof typeof languagesRC];
  const { nameLocal, bidi: isRTL } = getLocaleData(xcode);

  availableLocales[locale] = {
    // use urlPrefix as advised by RC team
    code: urlPrefix,
    momentCode: MOMENT_CODES[locale as keyof typeof MOMENT_CODES] || urlPrefix,
    name,
    nameLocal,
    isRTL,
    xcode,
  };
  return availableLocales;
}, {});
