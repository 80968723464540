import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import rentalcoverSharedMessages from '@whitelabel/rentalcover-shared/helpers/messages/commonMsg';
import { StyledNavButton, StyledNavIconWrapper } from '@whitelabel/rentalcover-shared/styled/StyledNavButton';
import { ReactComponent as IconHelp } from '@whitelabel/rentalcover-shared/media/images/icon-help.svg';
import { locales } from '@whitelabel/rentalcover-shared/helpers/site';
import FlagIcon from '@whitelabel/rentalcover-shared/components/FlagIcon';
import NavItem from '../NavItem';
import LocaleModal from '../../../../../components/LocaleModal';

export const HelpButton = () => {
  const { formatMessage } = useIntl();
  return (
    <NavItem key="help" path="/help" name={formatMessage(rentalcoverSharedMessages.helpLinkText)} icon={IconHelp} />
  );
};

export const ClaimsButton = () => {
  const { formatMessage } = useIntl();
  return <NavItem key="claims" path="/claims" name={formatMessage(rentalcoverSharedMessages.yourClaimsLinkText)} />;
};

export const ProtectionButton = () => {
  const { formatMessage } = useIntl();
  return (
    <NavItem key="claims" path="/account" name={formatMessage(rentalcoverSharedMessages.yourProtectionLinkText)} />
  );
};

export const LocaleButton = () => {
  const { locale } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleLocaleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <StyledNavButton onClick={toggleLocaleModal} buttonType="tertiary">
        <StyledNavIconWrapper className="nav-icon-wrapper" $withRounding>
          <FlagIcon locale={locales[locale].xcode} />
        </StyledNavIconWrapper>
        {locales[locale].nameLocal}
      </StyledNavButton>
      <LocaleModal isOpen={isModalOpen} toggle={toggleLocaleModal} />
    </>
  );
};
