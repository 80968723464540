import { get, isString, values } from 'lodash/fp';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import commonMsg from '@whitelabel/helpers/messages/commonMsg';
import { isJSON } from '@whitelabel/helpers/objects';
import { CP_ROOT } from './constants';
import { ICPError } from './types';
import { appIntl } from './intlSingletonService';

/**
 * API Error Response might not have a message field.
 */
type IRawResponse = string | (Omit<ICPError, 'message'> & { message?: string });

export const getCPAPIHost = (bookingID?: string, version: 'v1' | 'v2' = 'v1') =>
  CP_ROOT === 'xcover' ? getAPIHost(bookingID, version) : process.env.VITE_API_HOST;

const processResponse = (rawResponse: IRawResponse) => {
  const isUnParsedResponse = isString(rawResponse) && isJSON(rawResponse);
  return (isUnParsedResponse ? JSON.parse(rawResponse as string) : rawResponse) as IRawResponse;
};

const getHTMLError = (html?: string) => {
  if (!html) return undefined;
  const doc = new DOMParser().parseFromString(html, 'text/xml');
  const title = doc.getElementsByTagName('title')?.[0]?.innerHTML;
  return title ? `HTML Error: ${title}` : undefined;
};

const getErrorMessage = (response: IRawResponse) => {
  let appErrorMessage;
  let sentryErrorMessage;
  const commonErrorMsg = appIntl().formatMessage(commonMsg.error);

  if (isString(response)) {
    appErrorMessage = commonErrorMsg;
    sentryErrorMessage = getHTMLError(response) || response;
  } else {
    const msgField = get('message', response);
    const msgInErrorsField = values<string[] | undefined>(get('errors', response))?.[0]?.toString();
    const msgInExtraField = values<string | undefined>(get('extra', response))?.[0];
    const type = get('type', response);
    const code = get('code', response);
    const apiErrorMessage = msgInErrorsField || msgInExtraField || msgField;
    const htmlError = getHTMLError(apiErrorMessage);

    appErrorMessage = htmlError ? commonErrorMsg : apiErrorMessage || commonErrorMsg;
    sentryErrorMessage = `${type || code}: ${htmlError || apiErrorMessage || 'No error message'}`;
  }

  return { appErrorMessage, sentryErrorMessage };
};

interface ICreateCPErrorArgs {
  /**
   * most of the time, the status is HTTP status code.
   * but sometimes, it can be a string,
   * e.g. "PARSING_ERROR" when response is not returned correctly.
   */
  status: number | string;
  /**
   * error response currently is handled with the below content types:
   * 1. application/json
   * 2. text/html
   * 3. plain text
   */
  response: IRawResponse;
  responseHeaders?: Headers;
  /**
   * url might be empty when the queryFn is defined in the endpoint.
   */
  url?: string;
  endpointName: string;
}

export const createCPError = ({
  status,
  response: rawResponse,
  responseHeaders,
  url,
  endpointName,
}: ICreateCPErrorArgs): ICPError => {
  const response = processResponse(rawResponse);
  const { appErrorMessage, sentryErrorMessage } = getErrorMessage(response);

  const appError: ICPError = new Error(appErrorMessage, { cause: response });
  const sentryError: ICPError = new Error(sentryErrorMessage, { cause: response });

  const requestId = responseHeaders?.get('X-Request-Id');

  [appError, sentryError].forEach((error) => {
    error.isApiError = true;
    error.status = status;
    error.requestId = requestId;
    error.name = `${endpointName} - ${status}`;
    if (!isString(response)) {
      error.type = get('type', response);
      error.code = get('code', response);
      error.isJSON = isJSON(rawResponse);
      error.errors = get('errors', response);
      error.extra = get('extra', response);
    }
  });

  const pathname = url ? new URL(url).pathname : null;

  captureExceptionWithFullStory(sentryError, { url: pathname, errors: sentryError.errors, extra: sentryError.extra });

  return appError;
};
