import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import parseHtml from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { Container, Row } from 'reactstrap';
import StyledDivider from '@whitelabel/component-library/src/styled/StyledDivider';
import Brand from '../Brand';
import rentalcoverLogo from '../../media/images/rentalcover-logo-dark.svg';
import { SITE_NAME } from '../../helpers/constants';
import CollapsibleText from './CollapsibleText';
import messages from './messages';
import {
  StyledFooter,
  StyledLinksWrapper,
  StyledRichText,
  StyledBrandWrapper,
  StyledLinkContainer,
  StyledCollapsibleTextWrapper,
  StyledNoDisclaimerFooter,
  StyledVerticalDivider,
  StyledMinimumCollapsibleTextWrapper,
} from './styledFooter';

const splitDisclaimer = (disclaimer: string): [string, string] => {
  const [firstDisclaimerData, ...restMainDisclaimerData] = disclaimer.split('\n\n');

  return [firstDisclaimerData, restMainDisclaimerData.join('\n\n')];
};

interface ILink {
  url: string;
  text: string;
  rel?: string;
  target?: React.HTMLAttributeAnchorTarget;
}

// TODO: replace text with intl.formatmessage
const extendedLinks1 = (intl: IntlShape): ILink[] => [
  {
    url: `/${intl.locale}/4x4-excess`,
    text: '4x4 Excess Reduction',
  },
  {
    url: `/${intl.locale}/about-us`,
    text: 'About us',
  },
  {
    url: `/${intl.locale}/accessibility`,
    text: 'Accessibility',
  },
  {
    url: `/${intl.locale}/blog`,
    text: 'Blog',
  },
  {
    url: `/${intl.locale}/blog/car-rental-guide`,
    text: 'Renting a Car: Definitive Guide',
  },
  {
    url: `/${intl.locale}/blog/car-rental-insurance-mistakes`,
    text: 'Car rental insurance mistakes',
  },
  {
    url: `/${intl.locale}/driving-in-europe-rental-car-tips`,
    text: 'Driving in Europe',
  },
  {
    url: `/${intl.locale}/blog/europe-road-trip-itinerary-ideas`,
    text: 'Picturesque drives in Europe',
  },
  {
    url: `/${intl.locale}/blog/purchasing-car-rental-insurance`,
    text: 'Purchasing Car Rental Insurance',
  },
];

const extendedLinks2 = (intl: IntlShape): ILink[] => [
  {
    url: `/${intl.locale}/blog/rental-car-UK`,
    text: 'Renting a Car in the U.K.',
  },
  {
    url: `/${intl.locale}/blog/what-is-sli-supplemental-liability-insurance`,
    text: 'Supplemental Liability Insurance',
  },
  {
    url: `/${intl.locale}/CDW`,
    text: 'Collision Damage Waiver',
  },
  {
    url: `/${intl.locale}/excess-cover`,
    text: 'Excess Reduction',
  },
  {
    url: `/${intl.locale}/how-it-works`,
    text: 'Car Rental Cover: How It Works',
  },
  {
    url: `/${intl.locale}/indonesian-branch-codes`,
    text: 'Branch Codes for Indonesia',
  },
  {
    url: `/${intl.locale}/loss-damage-waiver-explained`,
    text: 'Rental Car Loss Damage Waiver',
  },
  {
    url: `/${intl.locale}/media-mentions`,
    text: 'Media',
  },
  {
    url: `/${intl.locale}/modern-slavery-statement`,
    text: 'Modern Slavery Statement',
  },
];

const extendedLinks3 = (intl: IntlShape): ILink[] => [
  {
    url: `/${intl.locale}/motorhome-excess-coverage`,
    text: 'Motorhome & Campervan Excess Reduction',
  },
  {
    url: `/${intl.locale}/partners`,
    text: 'Become A Partner',
  },
  {
    url: `/${intl.locale}/privacy`,
    text: 'Privacy Policy',
  },
  {
    url: `/${intl.locale}/rental-car-Australia`,
    text: 'Renting a car in Australia',
  },
  {
    url: `/${intl.locale}/rental-car-usa-guide`,
    text: 'Renting a car in the U.S.A',
  },
  {
    url: `/${intl.locale}/seniors-discount-car-rental`,
    text: 'Seniors Discount on Car Rental Insurance',
  },
  {
    url: `/${intl.locale}/standalone-rental-car-excess-cover`,
    text: 'Standalone Rental Car Excess Cover',
  },
  {
    url: `/${intl.locale}/terms`,
    text: 'Terms',
  },
  {
    url: `/${intl.locale}/what-we-do`,
    text: 'What We Do',
  },
];

const termsLink = (intl: IntlShape): ILink => ({
  url: `/${intl.locale}/terms`,
  text: 'Terms',
  rel: 'noopener noreferrer',
  target: '_blank',
});

const privacyLink = (intl: IntlShape): ILink => ({
  url: `/${intl.locale}/privacy`,
  text: 'Privacy policy',
  rel: 'noopener noreferrer',
  target: '_blank',
});

interface IFooter {
  showNavLinks?: boolean;
  showDisclaimer: 'none' | 'extended' | 'collapsed';
}

const Footer = ({ showNavLinks, showDisclaimer }: IFooter): JSX.Element => {
  const intl = useIntl();

  const brand = {
    title: SITE_NAME,
    logo: rentalcoverLogo,
  };

  const [firstDisclaimerData, restMainDisclaimerData] = splitDisclaimer(intl.formatMessage(messages.disclaimer));

  const restDisclaimerData = [
    restMainDisclaimerData,
    intl.formatMessage(messages.costComparisonDisclaimer),
    intl.formatMessage(messages.vehicleTypesDisclaimer),
  ].join('\n\n');

  const renderRentalCoverCopyright = () => (
    <span className="footer-copyright">{`${String.fromCharCode(169)} ${SITE_NAME} ${new Date().getFullYear()}`}</span>
  );

  const renderLink = (item: ILink) => (
    <a href={item.url} rel={item.rel} target={item.target}>
      {item.text}
    </a>
  );

  if (showDisclaimer === 'none') {
    return (
      <StyledFooter id="footer">
        <StyledMinimumCollapsibleTextWrapper>
          <StyledNoDisclaimerFooter>
            {renderRentalCoverCopyright()}
            <div className="footer-links">
              {renderLink(termsLink(intl))}
              <StyledVerticalDivider />
              {renderLink(privacyLink(intl))}
            </div>
          </StyledNoDisclaimerFooter>
        </StyledMinimumCollapsibleTextWrapper>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter id="footer">
      <StyledLinksWrapper>
        <Container>
          <Row>
            <StyledBrandWrapper>
              <Brand {...brand} />
              {renderRentalCoverCopyright()}
            </StyledBrandWrapper>
          </Row>
        </Container>
        {showNavLinks && (
          <Container>
            <StyledDivider $gap="1.5rem" />
            <Row>
              <StyledLinkContainer xs="12" md="4">
                {extendedLinks1(intl).map((link) => (
                  <Row key={link.text}>{renderLink(link)}</Row>
                ))}
              </StyledLinkContainer>
              <StyledLinkContainer xs="12" md="4">
                {extendedLinks2(intl).map((link) => (
                  <Row key={link.text}>{renderLink(link)}</Row>
                ))}
              </StyledLinkContainer>
              <StyledLinkContainer xs="12" md="4">
                {extendedLinks3(intl).map((link) => (
                  <Row key={link.text}>{renderLink(link)}</Row>
                ))}
              </StyledLinkContainer>
            </Row>
          </Container>
        )}
      </StyledLinksWrapper>
      <StyledCollapsibleTextWrapper>
        <Container>
          <Row>
            {showDisclaimer === 'collapsed' && (
              <CollapsibleText
                firstElement={<StyledRichText>{parseHtml(DOMPurify.sanitize(firstDisclaimerData))}</StyledRichText>}
                restElement={<StyledRichText>{parseHtml(DOMPurify.sanitize(restDisclaimerData))}</StyledRichText>}
              />
            )}
            {showDisclaimer === 'extended' && (
              <StyledRichText>
                {parseHtml(DOMPurify.sanitize(firstDisclaimerData))}
                {parseHtml(DOMPurify.sanitize(restDisclaimerData))}
              </StyledRichText>
            )}
          </Row>
        </Container>
      </StyledCollapsibleTextWrapper>
    </StyledFooter>
  );
};

export default Footer;
