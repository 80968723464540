import { lazy } from 'react';

export const ClaimsLanding = lazy(() => import('../containers/pages/ClaimsLanding'));
export const ClaimDetails = lazy(() => import('../containers/pages/ClaimDetails'));
export const ClaimDetailsDocument = lazy(() => import('../containers/pages/ClaimDetailsDocument'));

export const authRoutes = [
  {
    key: 'claimsLanding',
    path: '/claims',
    exact: true,
    component: ClaimsLanding,
  },
  {
    key: 'claimDetails',
    path: '/claims/:claimReference/details',
    exact: true,
    component: ClaimDetails,
  },
  {
    key: 'claimDetailsDocument',
    path: '/claims/:claimReference/documents/:documentName',
    exact: true,
    component: ClaimDetailsDocument,
  },
];
