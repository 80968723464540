import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyledAppLayout, StyledMain } from '@whitelabel/rentalcover-shared/styled/StyledRentalCoverLayout';
import { Media } from '@whitelabel/helpers/media';
import Footer from '@whitelabel/rentalcover-shared/components/Footer';
import { StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import PrimaryNavBar from './PrimaryNavBar';
import SecondaryNavBar from './SecondaryNavBar';
import CollapsibleNav from './PrimaryNavBar/CollapsibleNav';

interface ILayoutProps {
  children: ReactNode;
  className?: string;
  hideCustomer?: boolean;
  // Show nav links on footer
  showNavLinks?: boolean;
  // If no disclaimer is shown, minimal footer is shown
  showDisclaimer?: 'none' | 'extended' | 'collapsed';
  isLoading?: boolean;
}

/**
 * Newer layout, currently applied only for non-payment pages.
 * Newer NavBar does not have modal and modals are connected to redux stores
 */
const Layout = ({
  children,
  className,
  hideCustomer,
  showNavLinks,
  showDisclaimer = 'none',
  isLoading,
}: ILayoutProps): JSX.Element => {
  // TODO: check and add useCookieYesListener()
  const classes = ['app-layout', className].join(' ');

  const { bidi } = useIntl();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = () => {
    setMobileNavOpen((prev) => !prev);
  };

  if (isLoading) {
    return <StyledLoading />;
  }

  return (
    <StyledAppLayout $isNavOpen={isMobileNavOpen} $rtl={bidi} className={classes}>
      <PrimaryNavBar
        showHelpButton
        showGetQuoteButton
        showCustomer={!hideCustomer}
        isMobileNavOpen={isMobileNavOpen}
        toggleNav={toggleNav}
      />
      <Media lessThan="lg">
        <CollapsibleNav isMobileNavOpen={isMobileNavOpen} />
      </Media>
      <Media greaterThanOrEqual="lg">
        <SecondaryNavBar />
      </Media>
      <StyledMain>{children}</StyledMain>
      <Footer showNavLinks={showNavLinks} showDisclaimer={showDisclaimer} />
    </StyledAppLayout>
  );
};

export default Layout;
